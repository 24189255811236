import { combineReducers } from "redux";
import userReducer from "./userSlice";
import roleSlice from "./roleSlice";
import settingReducer from "./settingsSlice";
import sessionReducer from "./sessionSlice";
import evaluateesReducer from "./evaluetesAndReports/evaluetesAndReports";
import questionsReducer from "./QuestionsSlice/QuestionsSlice";

const rootReducer = combineReducers({
  user: userReducer,
  roles: roleSlice,
  setting: settingReducer,
  session: sessionReducer,
  evaluteesReportTo: evaluateesReducer,
  questions: questionsReducer,
});

export default rootReducer;
