import { fetchData } from "../helpers/fetchData";

const getList = async () => {
  try {
    let res = await fetchData("get", "/site-setting");

    if (res.data.status === 200) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (error) {
    console.log(error, "Error");
  }
};

const updateOne = async (payload = {}) => {
  try {
    const res = await fetchData(
      "PUT",
      `/site-setting/${payload.id}`,
      {
        body: payload,
      },
      "form-data"
    );

    if (res.data.status >= 200 && res.data.status <= 300) {
      return res.data;
    } else {
      if (res.data.error && typeof res.data.error === "object") {
        throw new Error(res.data.error[Object.keys(res.data.error)[0]]);
      } else if (res.data.message && typeof res.data.message === "object") {
        throw new Error(res.data.message[Object.keys(res.data.message)[0]]);
      } else {
        throw new Error(res.data.message);
      }
    }
  } catch (err) {
    throw err;
  }
};

export const siteSettingService = {
  getList,
  updateOne,
};
