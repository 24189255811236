import { createSlice } from "@reduxjs/toolkit";

const roleSlice = createSlice({
  name: "role",
  initialState: {},
  reducers: {
    setRoles: (state, action) => (state = action.payload),
  },
});
export const { setRoles } = roleSlice.actions;
export default roleSlice.reducer;
