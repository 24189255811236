import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect } from "react";
import { siteSettingService } from "./Services/siteSettingService";
import { setSiteSetting } from "./store/slices/settingsSlice";
const CreateRoutes = React.lazy(() => import("./routes"));

function App() {
  const dispatch = useDispatch();

  const faviconData = useSelector(
    (state) => state.setting?.siteSetting?.favicon
  );

  useEffect(() => {
    if (faviconData) {
      updateFaviconInBrowser(faviconData);
    }
  }, [faviconData]);

  const updateFaviconInBrowser = (faviconData) => {
    const faviconLink = document.createElement("link");
    faviconLink.rel = "icon";
    faviconLink.type = "image/x-icon";
    faviconLink.href = faviconData.url;

    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
      existingFavicon.remove();
    }

    document.head.appendChild(faviconLink);
  };

  const loadSiteSetting = useCallback(async () => {
    try {
      const response = await siteSettingService.getList();
      if (response?.status === 200) {
        dispatch(setSiteSetting(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    loadSiteSetting();
  }, [loadSiteSetting]);

  return <CreateRoutes />;
}

export default App;
