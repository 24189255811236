import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  evaluatees: [],
  reportsTo: [],
};

const evaluateesSlice = createSlice({
  name: "evaluteesAndReportsTo",
  initialState,
  reducers: {
    setEvalutees(state, action) {
      state.evaluatees = action.payload;
    },
    setReportsTo(state, action) {
      state.reportsTo = action.payload;
    },
  },
});
export const { setEvaluatees, setReportsTo } = evaluateesSlice.actions;
export default evaluateesSlice.reducer;
