import axios from "axios";

import { AUTH_TOKEN, REFRESh_TOKEN } from "../utils/constant";
import { toast } from "react-toastify";

const builderQueryString = (queries = []) => {
  let queryString = "";
  if (queries) {
    Object.keys(queries).forEach((query) => {
      if (Array.isArray(queries[query]) && queries[query].length) {
        queries[query].forEach((item, index) => {
          queries[`${query}[${index}]`] = item;
        });

        delete queries[query];
      }
    });

    queryString = new URLSearchParams(queries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

function objectToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const fetchData = async (
  method = "get",
  url = "",
  payload = { body: null, queries: null },
  contentType = "application/json",
  singleDelete = ""
) => {
  let accessToken = localStorage.getItem(AUTH_TOKEN);
  let refreshToken = localStorage.getItem(REFRESh_TOKEN);

  try {
    let baseUrl =
      process.env.REACT_APP_API_BASE_URL +
      process.env.REACT_APP_SERVER_VERSION_URL;
    const instance = axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": contentType,
        Accept: contentType,
      },
    });

    // Add a request interceptor
    instance.interceptors.request.use(
      function (config) {
        if (accessToken) {
          config.headers[`Authorization`] = "Bearer " + accessToken;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    let payloadData = payload.body;
    if (contentType.includes("form-data")) {
      payloadData = new FormData();
      payloadData = objectToFormData(payload.body, payloadData);
    }

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          error.response.data.status === 401 &&
          error.response.data.tokenExpired
        ) {
          instance
            .post("refreshToken", {
              refreshToken,
            })
            .then((res) => {
              localStorage.setItem(AUTH_TOKEN, res.data.accessToken);
              localStorage.setItem(REFRESh_TOKEN, res.data.refreshToken);
              window.dispatchEvent(new Event("storage"));
            })
            .catch((error) => {
              if (error?.response?.data?.status === 404) {
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(REFRESh_TOKEN);
                window.dispatchEvent(new Event("storage"));
              }
            });
        }
        return Promise.reject(error);
      }
    );

    const config = {
      url: `${url}${
        payload.queries ? builderQueryString(payload?.queries, contentType) : ""
      }`,
      method: method.toLowerCase(),
      headers: { "Content-Type": contentType },
    };

    if (singleDelete !== "single") {
      config.data = payloadData;
    }

    const res = await instance(config);

    if (res.status === 200 || res.statusText === "ok") {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    if (error?.response?.data?.status === 401) {
      toast.error(error?.response?.data?.message);
      localStorage.clear();
      localStorage.setItem(AUTH_TOKEN, "");
      localStorage.setItem(REFRESh_TOKEN, "");
      window.location.href = "/";
    }
    return error?.response;
  }
};
